<template>
  <div class="flex flex-col gap-2 items-start">
    <p>{{ $t('settings_newsletters.explanation_text') }}</p>
    <p class="uppercase">{{ $t('settings_newsletters.cta_text') }}</p>
    <ErrorMessage v-if="error"/>
    <LoadingSpinner v-if="loading"/>
    <div v-if="!error && !loading" class="grid grid-cols-2 gap-1">
      <div class="flex items-center gap-1" v-for="newsletter in filteredNewsletters">
        <input v-model="newsletter.subscribed" :disabled="loadingSave" :id="idForNewsletter(newsletter)" type="checkbox" class="checkbox"/>
        <label :for="idForNewsletter(newsletter)">{{ newsletter.platform }}.{{ newsletter.country }} <span
            class="uppercase " v-if="newsletter.language != locale">({{ newsletter.language }})</span></label>
      </div>
    </div>
    <div class="mt-1">
      <ButtonSubmit color="dark" :title="$t('general.save')" @click="saveNewsletters()" v-if="!loading && !error" :loading="loadingSave" :error="errorSave" />
    </div>
  </div>
</template>
<script setup lang="ts">

const locale = usePlatformSettings().locale;
const newsletters = ref<Newsletter[]>()
const loading = ref<boolean>(false);
const error = ref<boolean>(false);
const loadingSave = ref<boolean>(false);
const errorSave = ref<boolean>(false);

const idForNewsletter = (newsletter: Newsletter): string => {
  return 'newsletter-checkbox-' + newsletter.platform + '-' + newsletter.country + '-' + '-' + newsletter.language
}
const filteredNewsletters = computed(() => {
  const result = newsletters.value?.filter((newsletter) => {
    return newsletter.language == locale.value;
  });

  if (result?.length == 0) {
    return newsletters.value;
  }

  return result;

});

function getNewsletters() {
  loading.value = true;
  error.value = false;
  useApi().get<Newsletter[]>("/users/current/newsletters").then((response) => {
    newsletters.value = response;
  }).catch((e) => {
    error.value = true;
  }).finally(() => {
    loading.value = false;
  });
}

async function saveNewsletters() {
  if (!newsletters.value) {
    return;
  }
  const formData = new FormData();
  for (const newsletter of newsletters.value) {
    formData.append('countries[]', newsletter.country);
    formData.append('platforms[]', newsletter.platform);
    formData.append('languages[]', newsletter.language);
    formData.append('subscribed[]', newsletter.subscribed ? 'true' : 'false');
  }
  loadingSave.value = true;
  errorSave.value = false;
  return useApi().post<Newsletter[]>("/users/current/newsletters", formData).then((result) => {
    newsletters.value = result;
  }).catch((e) => {
    errorSave.value = true;
  }).finally(() => {
    loadingSave.value = false;
  });

}

await useAsyncData(() => getNewsletters());


</script>
